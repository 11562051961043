import Header from "./container/header";
import Navbar from "./components/navbar";
import StickyScrollSpyNav from "react-sticky-scrollspy-nav";
import React, { useEffect, useState, useRef, createRef } from "react";
import "./App.css";
import $ from "jquery";
import HeaderVideo from "./components/headerVideo";
import Section1 from "./container/section1";
import Section2 from "./container/section2";
import Section3 from "./container/section3";
import Section4 from "./container/section4";
import Section5 from "./container/section5";
import Section6 from "./container/section6";
import Section7 from "./container/section7";
import Platform from "react-platform-js";

function App() {
  const [showRemaingSection, setShow] = useState(false);
  const [scrollRange, setScrollRange] = useState(0);
  const [timer, setTimer] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [animate, setAnimate] = useState(false);
  const [top, setTop] = useState("");

  const videoRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimer(Number(timer) + 1);
    }, 1000);
  }, [timer]);

  const handleScroll = async () => {

    window.scroll({
      behavior: "smooth",
    });
    const position = window.scrollY;
    await setScrollRange(position);
    await setTop(position);
    setTimer(0)
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRange]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const section1 = useRef(null);
  const show = () => {
    setShow(true);
  };
  const options = {
    padding: "20px 0px 20px 0px",
    backgroundImage:
      "linear-gradient(to left, #4bb9ab, #6ac7bd, #86d5ce, #a1e4df, #bcf2ef)",
  };

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
  
  const os = iOS();
  const screenPosition =
    window.screenY ||
    document.body.scrollTop ||
    document.documentElement.scrollTop;

  let isSafari = navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//);
   
  useEffect(() => {


    let mobileBrowser;
    if (os) {

      if(isSafari){
        mobileBrowser = window.visualViewport.height + 20 ;
      }
      else{
        mobileBrowser = window.window.outerHeight - 30;
      }

    } else {
      mobileBrowser = window.window.outerHeight - 30;
    }
    console.log(timer)
    if (scrollRange === 0 && timer === 3) {
      $("body,html").animate(
        { scrollTop: isMobile ? mobileBrowser: window.innerHeight - 100 },
        800
      );
    }
  }, [timer]);

  return (
    <div>
      <StickyScrollSpyNav
        header={
          <div
            onWheel={handleScroll}
            style={
              isMobile
                ? {
                    height: scrollRange === 0 ? "100vh" : null,
                    transition: "all 1s ease-in-out",
                  }
                : {
                    height: scrollRange === 0 ? "100vh" : null,
                    transition: "all 1s ease-in-out",
                  }
            }
          >
            <HeaderVideo isMobile={isMobile} isSafari={isSafari} os={os} videoRef={videoRef}/>
          </div>
        }
        nav={[]}
        navContainerStyle={{}}
        navItemStyle={{ padding: 14, fontSize: 16 }}
      >
        <div
          style={{
            backgroundImage:
              "linear-gradient(to left, #4bb9ab, #6ac7bd, #86d5ce, #a1e4df, #bcf2ef)",
          }}
        >
          <Section1 show={show} ref={section1} isMobile={isMobile} />
          <Section2 isMobile={isMobile} isSafari={isSafari}/>
          <Section3 isMobile={isMobile} isSafari={isSafari}/>
          <Section4 isMobile={isMobile} isSafari={isSafari}/>
          <Section5 isMobile={isMobile} isSafari={isSafari}/>
          <Section6 isMobile={isMobile} isSafari={isSafari}/>
          <Section7 />
        </div>
      </StickyScrollSpyNav>
    </div>
  );
}

export default App;
