import React, { useState, useEffect } from "react";
import "./navbar.css";
import logo from "../../assets/images/WHITE NEW LOGO less space@2x.png";
function Navbar(props) {
    return (
      <div className="container">
          <img src={logo} alt="logo" className="logo"/>
      </div>
    );
  }
  
  export default Navbar;