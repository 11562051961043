import React, { useState, useEffect, useRef } from "react";
import groupIamge from "../../assets/images/Group 567@2x.png";
import chatImage from "../../assets/images/Rectangle 1877@2x.png";
import "./section2.css";
import Slider from "react-slick";
const pic1 = "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/gamification.png";
const pic2 = "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/livecommentary.png";
const pic3 = "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/livestats.png";
const pic5 = "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/matchvideos.png";
const pic4 = "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/smallconversations.png";
const pic6 = "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/trendingtweets.png";
function Section2(props) {
  const [scrollRange, setScrollRange] = useState("");
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollRange(position);
  };
  let width = window.innerWidth;
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // console.log(scrollRange)
  let image;
  const initialPosition = 25;
  const screenPosition = window.screenY - initialPosition;
  let v1,v2,v3,v4,v5,v6;
  if(!props.isMobile){
  if(width <= 1440)
  {
    v1= 1630;
    v2=1730;
    v3=1830;
    v4=1930;
    v5=2030;
    v6 =2130;
  }
  else {
    v1= 1750;
    v2=1850;
    v3=1950;
    v4=2050;
    v5=2250;
    v6 =2250;
  }
}
  else{
    if(props.isSafari){
      v1= 260;
      v2=1200;
      v3=1400;
      v4=1600;
      v5=1800;
      v6 =2000;
    }
    else{
    v1= 160;
    v2=1200;
    v3=1300;
    v4=1400;
    v5=1500;
    v6=1600;
    }
  }

  if (scrollRange < v1 - screenPosition) {
    image = pic1;
  } else if (scrollRange >= v1 - screenPosition && scrollRange < v2 - screenPosition) {
    image = pic2;
  } else if (scrollRange > v2 - screenPosition && scrollRange < v3 - screenPosition) {
    image = pic3;
  } else if (scrollRange > v3 - screenPosition && scrollRange < v4 - screenPosition) {
    image = pic4;
  } else if (scrollRange > v4 - screenPosition && scrollRange < v5 - screenPosition) {
    image = pic5;
  }
  else if (scrollRange > v5 - scrollRange) {
    image = pic6
  }


  return (
    <div className="section2-container" style={width > 1440 ? {paddingLeft:'200px',paddingRight:'200px'}: null}>
      <div className="section2-div1">
        {!props.isMobile ? <img src={"https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/matchfixtures.png"} className="section2-div-image" />: null}
      </div>
      <div className="section2-div2">
        <div className="section2-div2-l">
          <span className="section2-div2-l-span1">
            Enjoy live football like never before
          </span>
          <div></div>
          <div className="section2-div2-l-div ">
            <div className="section2-div2-l-div1">
              <div className="section-2-div2-l-div-details">
                <div className="section-2-div2-l-div-details-1">
                  {props.isMobile ?
                  <p className="section2-div2-l-div-p">
                    {/* <span
                      style={scrollRange < v4 - screenPosition ? { color: "#000000" ,transitionTimingFunction: 'linear',
                      transitionDuration: '.35s'} : null}
                    >
                      You'll never watch a live game without us again. 
                    </span> */}
                    <span
                      style={scrollRange > v2 - screenPosition ? { color: "#000000" ,transitionTimingFunction: 'linear',
                      transitionDuration: '.35s'} : null}
                    >
                           You'll never watch a live game without us again. 
                    </span>
                  </p> : null}
                </div>
              </div>
              <div></div>
              <img src={image} className="cards" style={{transitionTimingFunction: 'linear',
                      transitionDuration: '.35s'}}/>
            </div>
          </div>
        </div>
        <div className="section2-div2-r">
        {props.isMobile ? <img src={"https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/matchfixtures.png"} className="section2-div-image" />: null}
        <video className="section2-div2-r-video" loop muted autoPlay playsInline src={"https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/liveeventvid.mp4"} ></video>
        </div>
      </div>
    </div>
  );
}

export default Section2;
