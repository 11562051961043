import React, { useEffect, useState } from "react";
import HeaderVideo from "../../components/headerVideo";
import Navbar from "../../components/navbar";
import ReactPageScroller from "react-page-scroller";
import Section1 from "../section1";
import Section2 from "../section2";
function Header() {
    const [navBar, setNavbar] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const handlePageChange = number => {
      setCurrentPage(number);
    };
    const handleBeforePageChange = number => {
      console.log(number);
    };
   
   
    return (
      <div>
          <ReactPageScroller
          pageOnChange={handlePageChange}
          onBeforePageScroll={handleBeforePageChange}
          customPageNumber={currentPage}
          >
              <HeaderVideo />
              <Section1 currentPage={currentPage}/>
              <Section2 currentPage={currentPage}/>
            </ReactPageScroller>   
      </div>
    );
  }
  
  export default Header;