import React, { useState, useEffect } from "react";
import "./section5.css";
const pic1 =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/group1.png";
const pic2 =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/group2.png";
function Section3(props) {
  const [scrollRange, setScrollRange] = useState("");
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollRange(position);
  };
  let width = window.innerWidth;
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const initialPosition = 25;
  let v1, v2, v3, v4;
  if (!props.isMobile) {
    if (width <= 1440) {
      v1 = 1000;
      v2 = 4400;
    } else if (width > 1440 && width <= 1680) {
      v1 = 1000;
      v2 = 3860;
    } else {
      v1 = 4000;
      v2 = 5000;
    }
  } else {
    //mobile website safari
    if(props.isSafari){
      if(width < 414){

    v1 = 1000;
    v2 = 4150;
  
  }
  else if(width =>414){
     v1 = 1000;
    v2 = 4350;
    
  }
    } 
    else{
      //mobile android
    v1 = 1000;
    v2 = 4200;
   

  }
}




  const screenPosition = window.screenY - initialPosition;
  let image;
  if (scrollRange > v1 - screenPosition && scrollRange < v2 - screenPosition) {
    image = pic1;
  } else if (scrollRange > v2 - screenPosition) {
    image = pic2;
  }
  return props.isMobile ? (
    <div className="section5-container">
      <div className="section5-div-r">
        <div className="section5-div-r1">
          <span className="section5-span">
            And make sure your friends know you’re the GOAT
          </span>
        </div>
        <div className="section5-div-r2">
          <div className="section5-div-r2-d1">
            <p className="section5-p">
              <span
                style={
                  scrollRange > v1 - screenPosition &&
                  scrollRange < v2 - screenPosition
                    ? {
                        color: "#ffffff",
                        transitionTimingFunction: "linear",
                        transitionDuration: ".35s",
                      }
                    : null
                }
              >
                Chat, compete and have fun with your friends about football,
              </span>
              <span
                style={
                  scrollRange > v2 - screenPosition
                    ? {
                        color: "#ffffff",
                        transitionTimingFunction: "linear",
                        transitionDuration: ".35s",
                      }
                    : null
                }
              >
                {" "}
                everyday with Springchat’s friends section
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="section5-div-l">
        <img
          src={image}
          style={{
            transitionTimingFunction: "linear",
            transitionDuration: ".35s",
          }}
        />
      </div>
    </div>
  ) : (
    <div
      className="section5-container"
      style={
        width > 1440 ? { paddingLeft: "200px", paddingRight: "200px" } : null
      }
    >
      <div className="section5-div-l">
        <img
          src={image}
          style={{
            transitionTimingFunction: "linear",
            transitionDuration: ".35s",
          }}
        />
      </div>
      <div className="section5-div-r">
        <div className="section5-div-r1">
          <span className="section5-span">
            And make sure your friends know you’re the GOAT
          </span>
        </div>
        <div className="section5-div-r2">
          <div className="section5-div-r2-d1">
            <p className="section5-p">
              <span
                style={
                  scrollRange > v1 - screenPosition &&
                  scrollRange < v2 - screenPosition
                    ? {
                        color: "#ffffff",
                        transitionTimingFunction: "linear",
                        transitionDuration: ".35s",
                      }
                    : null
                }
              >
                Chat, compete and have fun with your friends about football,
              </span>
              <span
                style={
                  scrollRange > v2 - screenPosition
                    ? {
                        color: "#ffffff",
                        transitionTimingFunction: "linear",
                        transitionDuration: ".35s",
                      }
                    : null
                }
              >
                {" "}
                everyday with Springchat’s friends section
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
