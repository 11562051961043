import React, { useState, useEffect } from "react";
import goatImage2 from "../../assets/images/Goat status@2x.png";

import "./section4.css";
let width = window.innerWidth;
const tilesImage =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/profilestats.png";
const goatImage1 =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/goat.png";
const crown =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/icon.png";
function Section4(props) {
  const [scrollRange, setScrollRange] = useState("");
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollRange(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let image;
  const initialPosition = 0;
  let v1, v2, v3, v4, v5, v6;
  if (!props.isMobile) {
    console.log(width)
    //desktop website
    if(width <= 1440){
    v1 = 1000;
    v2 = 3400;
    v3 = 3500;

    }
    else if(width > 1440 && width <=1680){
      v1 = 1000;
      v2 = 3090;
      v3 = 3650;
    }
    else{
    v1 = 1000;
    v2 = 3800;
    // v3 = 3400;
 
    }
  } else {
    //mobile website safari
    if(props.isSafari){
      if(width < 414){

    v1 = 1000;
    v2 = 3200;
    v3 = 3700;
  }
  else if(width =>414){
     v1 = 1000;
    v2 = 3500;
    v3 = 3950;
  }
    } 
    else{
      //mobile android
    v1 = 1000;
    v2 = 3400;
    v3 = 3900;

  }
}
  
  const screenPosition = window.screenY - initialPosition;
  if (scrollRange > v1 - screenPosition && scrollRange < v2 - screenPosition) {
    image = crown;
  } else if (
    scrollRange > v2 - screenPosition 
  ) {
    image = goatImage1;
  } 

  return props.isMobile ? (
    <div className="section4-container">
      <div className="section4-div-l">
        <div>
          <span className="section4-span">Prove you’re the GOAT</span>
          <div className="section4-div-r">
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ width: "100%" }}>
                <p className="section4-div1-p">
                  <span
                    style={
                      scrollRange > v1 && scrollRange < v4
                        ? {
                            color: "#000000",
                            transitionTimingFunction: "linear",
                            transitionDuration: ".35s",
                          }
                        : null
                    }
                  >
                    There’s levels to this.{" "}
                  </span>
                  <span
                    style={
                      scrollRange > v4
                        ? {
                            color: "#000000",
                            transitionTimingFunction: "linear",
                            transitionDuration: ".35s",
                          }
                        : null
                    }
                  >
                    &nbsp;Prove you know football by climbing up the ranks and
                    becoming an expert.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "40px",
              paddingTop: "20px",
              marginLeft: "20px",
              marginRight: "20px"
            }}
          >
            <img
              src={image}
              style={{
                width: "100%",
                transitionTimingFunction: "linear",
                transitionDuration: ".35s",
              }}
            />
          </div>
        </div>
      </div>
      <div className="section4-div-r">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
          }}
        >
          <img
            src={tilesImage}
            style={{
              transitionTimingFunction: "linear",
              transitionDuration: ".35s",
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="section4-container" style={width > 1440 ? {paddingLeft:'200px',paddingRight:'200px'}: null}>
      <div className="section4-div-l">
        <div>
          <span className="section4-span">Prove you’re the GOAT</span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "40px",
              paddingTop: "40px",
            }}
          >
            <img
              src={image}
              style={{
                width: "60%",
                transitionTimingFunction: "linear",
                transitionDuration: ".35s",
              }}
            />
          </div>
        </div>
      </div>
      <div className="section4-div-r">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "40px",
            paddingRight: "50px",
          }}
        >
          <div style={{ width: "50%" }}>
            <p className="section4-div1-p">
              <span
                style={
                  scrollRange > v1 && scrollRange < v2
                    ? {
                        color: "#000000",
                        transitionTimingFunction: "linear",
                        transitionDuration: ".35s",
                      }
                    : null
                }
              >
                There’s levels to this.{" "}
              </span>
              <span
                style={
                  scrollRange > v2 
                    ? {
                        color: "#000000",
                        transitionTimingFunction: "linear",
                        transitionDuration: ".35s",
                      }
                    : null
                }
              >
                &nbsp;Prove you know football by climbing up the ranks and
                becoming an expert.
              </span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
          }}
        >
          <img
            src={tilesImage}
            style={{
              transitionTimingFunction: "linear",
              transitionDuration: ".35s",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Section4;
