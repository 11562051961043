import React, { useState, useEffect } from "react";
import "./section7.css";

function Section3(props) {
  const [scrollRange, setScrollRange] = useState("");
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollRange(position)
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log(scrollRange)
  return (
 
      <div className="section7-container"  >
        
    </div>
  );
}

export default Section3;
