import React, { useState, useEffect } from "react";
import $ from "jquery";

import "./section3.css";

const pic1 =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/newscard.png";
const pic2 =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/youtubecard.png";
const pic3 =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/tweetcard.png";
const list =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/newsoff.png";
const hList =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/newson.png";
const youtube =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/youtubeoff.png";
const hYoutube =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/youtubeon.png";
const twitter =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/twitteroff.png";
const hTwitter =
  "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/twitteron.png";
function Section3(props) {
  const [scrollRange, setScrollRange] = useState("");
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollRange(position);
  };
  let width = window.innerWidth;
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const initialPosition = 25;
  const screenPosition = window.screenY - initialPosition;
  let v1, v2, v3, v4, v5;
  //Desktop Website controls
  if (!props.isMobile) {
    if (width <= 1440) {
      v1 = 1000;
      v2 = 2450;
      v3 = 2650;
      v4 = 2850;
    }
    else if(width > 1440 && width <= 1680) {
      v1 = 1000;
      v2 = 2300;
      v3 = 2520;
      v4 = 3050;
    }
    else {
      v1 = 1000;
      v2 = 3000;
      v3 = 3200;
      v4 = 3400;
    }
    //Mobile iOS website controls
  } else {
    if(props.isSafari){
      if(width < 414){

      v1= 1000;
      v2=2250;
      v3=2400;
      v4=2550;
    }
    else if(width =>414){
      v1= 1000;
      v2=2550;
      v3=2700;
      v4=2850;
    }
  }
    //Android mobile website
    else{
    v1 = 1000;
    v2 = 2450;
    v3 = 2550;
    v4 = 2750;
  }
}
console.log("++++>",scrollRange)
  
  let image;
  if (scrollRange > v1 - screenPosition && scrollRange < v2 - screenPosition) {
    image = pic1;
  } else if (
    scrollRange > v2 - screenPosition &&
    scrollRange < v3 - screenPosition
  ) {
    image = pic2;
  } else if (scrollRange > v3 - screenPosition) {
    image = pic3;
  }
  const transitions = {
    transitionTimingFunction: "linear",
    transitionDuration: ".35s",
    opacity: 1,
  };
  const iconTransitions = {
    transitionTimingFunction: "linear",
    transitionDuration: ".99s",
  };
  let lines;
  if (scrollRange > v1 - screenPosition && scrollRange < v2 - screenPosition) {
    lines = "The latest news summarised and tailored to you";
  } else if (
    scrollRange > v2 - screenPosition &&
    scrollRange < v3 - screenPosition
  ) {
    lines = "Press conferences, goals, analysis - never miss a moment";
  } else if (scrollRange > v3 - screenPosition) {
    lines = "The best (and worst) opinions straight from Twitter";
  }

  const stopScrolling = () => {
    var scrollCount = 0,
      latestScrollTop = 0,
      doc = document.documentElement,
      top = 0;
    $(window).bind("scroll", function (e) {
      top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      if (latestScrollTop < top) {
        scrollCount += 1;
      } else {
        scrollCount -= 1;
      }
      latestScrollTop = top;
      console.log("==>", scrollCount);
    });
  };
  return props.isMobile ? (
    <div
      className="section3-container"
      onWheel={() => {
        stopScrolling();
      }}
    >
      <div className="section3-div3">
        <p className="section3-div3-p" style={transitions}>
          {lines}
        </p>
      </div>
      <div style={{ display: "flex" }}>
        <div className="section3-div1">
          <img
            src={
              scrollRange > v1 - screenPosition &&
              scrollRange < v2 - screenPosition
                ? hList
                : list
            }
            style={
              scrollRange > v1 - screenPosition &&
              scrollRange < v2 - screenPosition
                ? iconTransitions
                : { width: "12px", height: "12px", objectFit: "fill" }
            }
          />
          <img
            src={
              scrollRange > v2 - screenPosition &&
              scrollRange < v3 - screenPosition
                ? hYoutube
                : youtube
            }
            style={
              scrollRange > v2 - screenPosition &&
              scrollRange < v3 - screenPosition
                ? {
                    marginTop: "14px",
                    marginBottom: "14px",
                    transitionTimingFunction: "linear",
                    transitionDuration: ".35s",
                  }
                : {
                    marginTop: "14px",
                    marginBottom: "14px",
                    width: "12px",
                    height: "10px",
                    objectFit: "fill",
                  }
            }
          />
          <img
            src={scrollRange > v3 - screenPosition ? hTwitter : twitter}
            style={
              scrollRange > v3 - screenPosition
                ? iconTransitions
                : { width: "12px", height: "10px", objectFit: "fill" }
            }
          />
        </div>
        <div className="section3-div2">
          <img src={image} style={iconTransitions} />
        </div>
      </div>
    </div>
  ) : (
    <div
      className="section3-container"
      style={
        width > 1440 ? { paddingLeft: "200px", paddingRight: "200px" } : null
      }
    >
      <div className="section3-div1">
        <img
          src={
            scrollRange > v1 - screenPosition &&
            scrollRange < v2 - screenPosition
              ? hList
              : list
          }
          style={
            scrollRange > v1 - screenPosition &&
            scrollRange < v2 - screenPosition
              ? iconTransitions
              : { width: "16px", height: "16px", objectFit: "fill" }
          }
        />
        <img
          src={
            scrollRange > v2 - screenPosition &&
            scrollRange < v3 - screenPosition
              ? hYoutube
              : youtube
          }
          style={
            scrollRange > v2 - screenPosition &&
            scrollRange < v3 - screenPosition
              ? {
                  marginTop: "40px",
                  marginBottom: "40px",
                  transitionTimingFunction: "linear",
                  transitionDuration: ".35s",
                }
              : {
                  marginTop: "40px",
                  marginBottom: "40px",
                  width: "16px",
                  objectFit: "fill",
                }
          }
        />
        <img
          src={scrollRange > v3 - screenPosition ? hTwitter : twitter}
          style={
            scrollRange > v3 - screenPosition
              ? iconTransitions
              : { width: "16px", height: "14px", objectFit: "fill" }
          }
        />
      </div>
      <div className="section3-div2">
        <img src={image} style={iconTransitions} />
      </div>
      <div className="section3-div3">
        <p
          className="section3-div3-p"
          style={
            scrollRange > v1 - screenPosition &&
            scrollRange < v2 - screenPosition
              ? transitions
              : null
          }
        >
          The latest news summarised and tailored to you
        </p>
        <br />
        <br />
        <p
          className="section3-div3-p"
          style={
            scrollRange > v2 - screenPosition &&
            scrollRange < v3 - screenPosition
              ? transitions
              : null
          }
        >
          Press conferences, goals, analysis - never miss a moment
        </p>
        <br />
        <br />
        <p
          className="section3-div3-p"
          style={scrollRange > v3 - screenPosition ? transitions : null}
        >
          The best (and worst) opinions straight from Twitter
        </p>
      </div>
    </div>
  );
}

export default Section3;
