import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import CustomCarousel from "../../components/carousel";
import "react-multi-carousel/lib/styles.css";
import imageOne from "../../assets/images/Carousel image 1.png";
import imageTwo from "../../assets/images/Carousel image 2.png";
import imageThree from "../../assets/images/Carousel image 3.png";
import imageFour from "../../assets/images/Carousel image 4.png";
import marqueeImage from "../../assets/images/websitebanner2levels.png";
import appleIcon from "../../assets/images/apple.png";
import androidIcon from "../../assets/images/android.png";
import mobileCarouselImage from "../../assets/images/The New Home of Sports Conversation@2x.png";
import Marquee from "react-fast-marquee";
import $ from "jquery";
import "./section1.css";
import Slider from "react-slick";
function Section1(props) {
  const divRef = useRef(null);
  const [reinit, setReinit] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollRange, setScrollRange] = useState("");

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollRange(position);
  };

  useEffect(() => {
    console.log("iis mobile", props.isMobile);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const preventDefault = (e) => {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  };

  const slider = useRef(null);
  function scroll(e) {
    if (slider === null) return 0;

    e.wheelDelta > 0 ? slider.current.slickNext() : slider.current.slickPrev();
  }

  useEffect(() => {
    window.addEventListener("wheel", scroll, true);

    return () => {
      window.removeEventListener("wheel", scroll, true);
    };
  }, []);

  let width = window.innerWidth;
  return (
    <div>
      <div className="section1-container" >
        <div style={{ display: "flex" }}>
          <div className="section1-part1" style={width > 1440 ? {paddingLeft:'200px',paddingRight:'200px'}: null}>
            <div className="section1-part1-div1" >
              <span className="section1-part1-span1">
                You can only live the moment, <br /> in the moment.
              </span>
            </div>
            <div className="section1-part1-div2"> 
              <div className="section1-part1-div2-div">
                <button
                  className="download-button"
                  onClick={() =>
                    $("body,html").animate({ scrollTop: 5000 }, 800)
                  }
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <span className="button-text">Download</span>
                    <div
                    style={{background: 'url(https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/apple.png) no-repeat',
                    width: "20px",
                    marginRight: "10px",
                    marginLeft: "10px",
                    backgroundSize: 'contain'
                  }}
                    ></div>
                    {/* <img
                      src={
                        "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/apple.png"
                      }
                      style={{
                        width: "20px",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    /> */}
                         <div
                    style={{background: 'url(https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/android.png) no-repeat',
                    width: "20px",
                    backgroundSize: 'contain'
                  }}
                    ></div>
                    {/* <img
                      src={
                        "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/android.png"
                      }
                      style={{ width: "20px" }}
                    /> */}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
        // class="item active"
        // style={!props.isMobile ? { marginTop: "54px" } : null}
        >
          {!props.isMobile ? (
            <div style={{paddingLeft:'10px',paddingRight:'10px'}}>
              <img src="https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/desktoplandingbanner.png"/>
            </div>
          ) : (
            <div className="carousel-container-mobile">
              <img src="https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/mobilelandingimagefinal.png"/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Section1;
