import React, { useState, useEffect } from "react";
import "./section6.css";
import image2 from "../../assets/images/google-play-badge@2x.png";
const image1 = "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/appstore.png";
function Section6(props) {
  const [scrollRange, setScrollRange] = useState("");
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollRange(position);
  };
  let width = window.innerWidth;
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log(scrollRange);
  return (
    <div className="section6-container" style={width > 1440 ? {paddingLeft:'200px',paddingRight:'200px'}: null}>
      <div style={ props.isMobile ? {width:"100%"} : { width: "80%" }}>
        <span className="section6-span">
          For fans who know what they’re talking about
        </span>
        <div className="section6-div1">
          <img
            src={image1}
            className="section6-downloads1"
            onClick={() => {
              window.open(
                "https://springchat.app.link/jqjySbBWHvb",
                "_blank"
              );
            }}
          />
          <img
            src={image2}
            className="section6-downloads2"
            onClick={() => {
              window.open(
                "https://springchat.app.link/VHgeNSLWHvb",
                "_blank"
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Section6;
