import React, { useState, useEffect, useRef } from "react";
import { Transition } from "react-transition-group";
import poster from "../../assets/images/poster.png";
import Logo from "../../assets/images/WHITE NEW LOGO less space@2x.png";
import "./headerVideo.css";
import { propTypes } from "react-addons-css-transition-group";
import Platform from 'react-platform-js';
function HeaderVideo(props) {
  const [, setIsVideoLoaded] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  const [scrollRange, setScrollRange] = useState("");
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollRange(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  let noVideoLogo = {
    marginTop: "450px",
    width: "600px",
    height: "100px",
  };
  const nodeRef = useRef(null);

  const dynamicDiv = {
    opacity: `${scrollRange / 300}`,
  };
  let mobileBrowser;
  if(props.isSafari){
    mobileBrowser = `${scrollRange + 80}px`
  }
  else {
    mobileBrowser = `${scrollRange + 50}px`
  }
  const dynamicImage = {
    width: !props.isMobile
      ? scrollRange < 656
        ? `${850 - scrollRange}px`
        : "201.5px"
      : scrollRange < 656
      ? `${450 - scrollRange/2}px`
      : "138.5px",
    marginTop: props.isMobile ? mobileBrowser : `${scrollRange}px`,
  };

  return (
    <div className="header-video-container">
      {scrollRange === 0 || scrollRange === "" ? (
        <video
          ref={nodeRef}
          autoPlay
          loop
          muted
          playsInline
          src={
            props.isMobile
              ? "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/landingvidmobile.mp4"
              : "https://springchatwebsite.s3.eu-west-1.amazonaws.com/newwebsite/landingviddesktop.mp4"
          }
          className="video-element"
          // poster={poster}
        ></video>
      ) : null}
      <div className="dynamicDiv" style={dynamicDiv} id="nav">
        <img src={Logo} style={dynamicImage} />
      </div>
    </div>
  );
}

export default HeaderVideo;
